import React, { ReactNode } from 'react';
import { ReserveIncentiveResponse } from '@yldr/math-utils/dist/esm/formatters/incentive/calculate-reserve-incentives';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { Trans } from '@lingui/macro';
import { Box, FormControlLabel, Skeleton, SvgIcon, Switch, SxProps, Theme, Typography } from '@mui/material';
import { parseUnits } from 'ethers/lib/utils';
import {
  IsolatedDisabledBadge,
  IsolatedEnabledBadge,
  UnavailableDueToIsolationBadge,
} from 'src/components/isolationMode/IsolatedBadge';
import { Row } from 'src/components/primitives/Row';
import { CollateralType } from 'src/helpers/types';

import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';
import { HealthFactorNumber } from '../../HealthFactorNumber';
import { IncentivesButton } from '../../incentives/IncentivesButton';
import { FormattedNumber, FormattedNumberProps } from '../../primitives/FormattedNumber';
import { TokenIcon } from '../../primitives/TokenIcon';
import { GasStation } from '../GasStation/GasStation';

export interface TxModalDetailsProps {
  gasLimit?: string;
  slippageSelector?: ReactNode;
  skipLoad?: boolean;
  disabled?: boolean;
  chainId?: number;
  header?: ReactNode;
  description?: string;
  sx?: SxProps<Theme>
  bottomSlot?: ReactNode;
}

const ArrowRightIcon = (
  <SvgIcon color="primary" sx={{ fontSize: '14px', mx: 1 }}>
    <ArrowNarrowRightIcon />
  </SvgIcon>
);

export const TxModalDetails: React.FC<TxModalDetailsProps> = ({
  gasLimit,
  slippageSelector,
  bottomSlot,
  skipLoad,
  disabled,
  children,
  chainId,
  header,
  description,
  sx,
}) => {
  return (
    <Box sx={{ mt: 6, ...sx }}>
      <Typography sx={{ mb: 2 }} variant="secondary13" color="text.tertiary">
        <Trans>Transaction overview</Trans>
      </Typography>
      {header}
      <Box
        sx={(theme) => ({
          py: 3,
          px: 4,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          '.MuiBox-root:last-of-type': {
            mb: 0,
          },
        })}
      >
        {children}
      </Box>
      {bottomSlot && (
        <Box sx={{ mt: 5 }}>
          {bottomSlot}
        </Box>
      )}
      {description && (
        <Typography variant="secondary12" color="text.tertiary" sx={{ mt: 2 }}>{description}</Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <GasStation
          chainId={chainId}
          gasLimit={parseUnits(gasLimit || '0', 'wei')}
          skipLoad={skipLoad}
          disabled={disabled}
          rightComponent={slippageSelector}
        />
      </Box>
    </Box>
  );
};

interface DetailsNumberLineProps extends Omit<FormattedNumberProps, 'value'> {
  description: ReactNode;
  tooltip?: ReactNode;
  value?: FormattedNumberProps['value'];
  futureValue?: FormattedNumberProps['value'];
  numberPrefix?: ReactNode;
  iconSymbol?: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export const DetailsNumberLine = ({
  description,
  tooltip,
  value,
  futureValue,
  numberPrefix,
  postfix,
  iconSymbol,
  loading = false,
  onClick,
  captionColor,
  sx,
  ...rest
}: DetailsNumberLineProps) => (
  <Row
    caption={description}
    tooltip={tooltip}
    captionVariant="description"
    captionColor={captionColor}
    mb={2}
    onClick={onClick}
    sx={sx}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 8 }}>
      {loading ? (
        <Skeleton variant="rectangular" height={20} width={100} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          {iconSymbol && <TokenIcon symbol={iconSymbol} sx={{ mr: 1, fontSize: '16px' }}/>}
          {numberPrefix && <Typography sx={{ mr: 1 }}>{numberPrefix}</Typography>}
          {value !== undefined && <FormattedNumber value={value} variant="secondary14" {...rest} />}
          {postfix && <Typography sx={{ mr: 1 }} noWrap>{postfix}</Typography>}
          {futureValue && (
            <>
              {ArrowRightIcon}
              <FormattedNumber value={futureValue} variant="secondary14" {...rest} />
            </>
          )}
        </>
      )}
    </Box>
  </Row>
);

interface ITokenDetailsLineProps {
  description: ReactNode;
  tokenA?: ComputedReserveData;
  tokenB?: ComputedReserveData;
  valueA?: FormattedNumberProps['value'];
  valueB?: FormattedNumberProps['value'];
  loading?: boolean;
}

export const TokenDetailsLine = ({ tokenA, tokenB, valueA, valueB, description, loading }: ITokenDetailsLineProps) => (
  <Row caption={description} captionVariant="description" mb={2} sx={{ flexWrap: 'wrap' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 8, mb: 0.5 }}>
      {loading ? (
        <Skeleton variant="rectangular" height={20} width={100} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          {tokenA?.iconSymbol && <TokenIcon symbol={tokenA.iconSymbol} sx={{ mr: 1, fontSize: '16px' }} />}
          {valueA !== undefined && <FormattedNumber value={valueA} variant="secondary14" {...tokenA} />}
        </>
      )}
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', flex: '0 0 100%' }}>
      {loading ? (
        <Skeleton variant="rectangular" height={20} width={100} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          {tokenB?.iconSymbol && <TokenIcon symbol={tokenB.iconSymbol} sx={{ mr: 1, fontSize: '16px' }} />}
          {valueB !== undefined && <FormattedNumber value={valueB} variant="secondary14" {...tokenB} />}
        </>
      )}
    </Box>
  </Row>
);

interface DetailsNumberLineWithSubProps {
  description: ReactNode;
  symbol: ReactNode;
  value?: string;
  valueUSD?: string;
  futureValue: string;
  futureValueUSD: string;
  hideSymbolSuffix?: boolean;
  color?: string;
  tokenIcon?: string;
  loading?: boolean;
}

export const DetailsNumberLineWithSub = ({
  description,
  symbol,
  value,
  valueUSD,
  futureValue,
  futureValueUSD,
  hideSymbolSuffix,
  color,
  tokenIcon,
  loading = false,
}: DetailsNumberLineWithSubProps) => (
  <Row caption={description} captionVariant="description" mb={4} align="flex-start">
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {loading ? (
        <>
          <Skeleton variant="rectangular" height={20} width={100} sx={{ borderRadius: '4px' }} />
          <Skeleton
            variant="rectangular"
            height={15}
            width={80}
            sx={{ borderRadius: '4px', marginTop: '4px' }}
          />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {value && (
              <>
                <FormattedNumber value={value} variant="secondary14" color={color} />
                {!hideSymbolSuffix && (
                  <Typography ml={1} variant="secondary14">
                    {symbol}
                  </Typography>
                )}
                {ArrowRightIcon}
              </>
            )}
            {tokenIcon && <TokenIcon symbol={tokenIcon} sx={{ mr: 1, fontSize: '14px' }} />}
            <FormattedNumber value={futureValue} variant="secondary14" color={color} />
            {!hideSymbolSuffix && (
              <Typography ml={1} variant="secondary14">
                {symbol}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {valueUSD && (
              <>
                <FormattedNumber value={valueUSD} variant="helperText" compact symbol="USD" />
                {ArrowRightIcon}
              </>
            )}
            <FormattedNumber value={futureValueUSD} variant="helperText" compact symbol="USD" />
          </Box>
        </>
      )}
    </Box>
  </Row>
);

export interface DetailsCollateralLine {
  collateralType: CollateralType;
}

export const DetailsCollateralLine = ({ collateralType }: DetailsCollateralLine) => (
  <Row caption={<Trans>Collateralization</Trans>} captionVariant="description" mb={4}>
    <CollateralState collateralType={collateralType} />
  </Row>
);

interface CollateralStateProps {
  collateralType: CollateralType;
}

export const CollateralState = ({ collateralType }: CollateralStateProps) => (
  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
    {
      {
        [CollateralType.ENABLED]: (
          <Typography variant="description" color="success.main">
            <Trans>Enabled</Trans>
          </Typography>
        ),
        [CollateralType.ISOLATED_ENABLED]: (
          <IsolatedEnabledBadge
            typographyProps={{ variant: 'description', color: 'warning.main' }}
          />
        ),
        [CollateralType.DISABLED]: (
          <Typography variant="description" color="error.main">
            <Trans>Disabled</Trans>
          </Typography>
        ),
        [CollateralType.UNAVAILABLE]: (
          <Typography variant="description" color="error.main">
            <Trans>Unavailable</Trans>
          </Typography>
        ),
        [CollateralType.ISOLATED_DISABLED]: <IsolatedDisabledBadge />,
        [CollateralType.UNAVAILABLE_DUE_TO_ISOLATION]: <UnavailableDueToIsolationBadge />,
      }[collateralType]
    }
  </Box>
);

interface DetailsIncentivesLineProps {
  futureIncentives?: ReserveIncentiveResponse[];
  futureSymbol?: string;
  incentives?: ReserveIncentiveResponse[];
  // the token yielding the incentive, not the incentive itself
  symbol: string;
  loading?: boolean;
}

export const DetailsIncentivesLine = ({
  incentives,
  symbol,
  futureIncentives,
  futureSymbol,
  loading = false,
}: DetailsIncentivesLineProps) => {
  if (!incentives || incentives.filter((i) => i.incentiveAPR !== '0').length === 0) return null;
  return (
    <Row caption={<Trans>Rewards APR</Trans>} captionVariant="description" mb={4} minHeight={24}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {loading ? (
          <Skeleton variant="rectangular" height={20} width={100} sx={{ borderRadius: '4px' }} />
        ) : (
          <>
            <IncentivesButton incentives={incentives} symbol={symbol} />
            {futureSymbol && (
              <>
                {ArrowRightIcon}
                <IncentivesButton incentives={futureIncentives} symbol={futureSymbol}/>
                {futureIncentives && futureIncentives.length === 0 && (
                  <Typography variant="secondary14">
                    <Trans>None</Trans>
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Row>
  );
};

export interface DetailsHFLineProps {
  healthFactor: string;
  futureHealthFactor: string;
  visibleHfChange: boolean;
  loading?: boolean;
}

export const DetailsHFLine = ({
  healthFactor,
  futureHealthFactor,
  visibleHfChange,
  loading = false,
}: DetailsHFLineProps) => {
  if (healthFactor === '-1' && futureHealthFactor === '-1') return null;
  return (
    <Row
      caption="Health factor"
      captionVariant="description"
      mb={4}
      align="flex-start"
    >
      <Box sx={{ textAlign: 'right' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {loading ? (
            <Skeleton variant="rectangular" height={20} width={80} sx={{ borderRadius: '4px' }} />
          ) : (
            <>
              <HealthFactorNumber value={healthFactor} variant="secondary14" />

              {visibleHfChange && (
                <>
                  {ArrowRightIcon}

                  <HealthFactorNumber
                    value={isNaN(Number(futureHealthFactor)) ? healthFactor : futureHealthFactor}
                    variant="secondary14"
                  />
                </>
              )}
            </>
          )}
        </Box>

        <Typography variant="helperText" color="text.secondary">
          Liquidation at {'<1.0'}
        </Typography>
      </Box>
    </Row>
  );
};

export interface DetailsUnwrapSwitchProps {
  unwrapped: boolean;
  setUnWrapped: (value: boolean) => void;
  label: ReactNode;
}

export const DetailsUnwrapSwitch = ({
  unwrapped,
  setUnWrapped,
  label,
}: DetailsUnwrapSwitchProps) => (
  <Row captionVariant="description" sx={{ mt: 5 }}>
    <FormControlLabel
      sx={{ mx: 0 }}
      control={
        <Switch
          disableRipple
          checked={unwrapped}
          onClick={() => setUnWrapped(!unwrapped)}
          data-cy={'wrappedSwitcher'}
        />
      }
      labelPlacement="end"
      label={label}
    />
  </Row>
);
